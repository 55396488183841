import * as React from 'react';
import * as comFunc from 'common/functions';
import './RightIcons.scss';

//自動更新
const UPDATE_TIME = Number(process.env.REACT_APP_AUTO_UPDATE_TIME);

export const RightIcons = () => {
	//アイデアポストリンク
	const [ideaPostLink, setIdeaPostLink] = React.useState("#");
	//新着判定用
	const [isIdeaPostNew, setIsIdeaPostNew] = React.useState(false);
	const [isGoikenbakoNew, setIsGoikenbakoNew] = React.useState(false);

	React.useEffect(() => {
		//アイデアポスト
		const getIdeaPost = async () => {
			let data = await comFunc.callAPI(process.env.REACT_APP_API_SERVER + "/ideapost/banner");
			if (data) {
				const update_date = data.updated_at.replace(/[- :]/g, ''); //数字のみ抜き出し
				setIsIdeaPostNew(isContentNew("idea-post", update_date)); //新着判定  
				setIdeaPostLink(data.link); //リンクをセット
			}
		};
		//GO意見箱
		const getContents = async () => {
			let data = await comFunc.callAPI(process.env.REACT_APP_API_SERVER + "/content/date");
			if (data) {
				setIsGoikenbakoNew(isContentNew("goikenbako", data["goikenbako"]));
			}
		}
		getIdeaPost();
		getContents();

		// 自動更新
		const autoUpdatingId = setInterval(() => {
			getIdeaPost();
			getContents();
		}, UPDATE_TIME);
		return () => clearInterval(autoUpdatingId);
	}, []);

	//新着判定
	const isContentNew = (name: string, update_date: string) => {

		const lastUpdateDate = comFunc.getCookie(name + '-update-date'); //更新日付のCookie
		const elements = document.getElementsByClassName(name); //対象の画像とaタグ
		const contents = Array.from(elements);

		contents.forEach(content => {
			content.setAttribute("data-updatedate", update_date); //data属性に更新日付をセット
		});

		//最終更新日付のCookieが存在しない または 更新日付が更新されている
		if (!lastUpdateDate || lastUpdateDate !== update_date) {
			return true; //新着とする
		}
		return false; //新着としない
	};

	//新着用のCookieを更新
	const setCookieUpdateDate = (ev: React.MouseEvent<HTMLElement>) => {
		let target = ev.currentTarget;
		//data属性が初期値の空文字以外の場合
		if (target.dataset.updatedate !== "") {
			//有効期限を1年とし、対象の更新日付をCookieにセット
			comFunc.setCookie(target.className + '-update-date', target.dataset.updatedate, "31536000");
		}
	};

	return (
		<div className="m-righticons">
			{/* 健康診断 */}
			<div className="item">
				<div className=""></div>
				<img
					alt="健康診断"
					className="asc-2022"
					src={require(`assets/imgs/ada_kenpo_icon.jpg`)}
					data-updatedate=""
					onClick={() => {
						comFunc.executeGtagEventTracking("main_link", "健康診断");
						window.open("https://secure.apap.jp/sso/servlet/SSOLoginServ?gid=G348&mode=pers&hq=1");
					}}
				/>
				<div className="item__option">
					<div className="item__option__wrapper">
						<a
							className="asc-2022"
							href="https://secure.apap.jp/sso/servlet/SSOLoginServ?gid=G348&mode=pers&hq=1"
							target="_blank"
							rel="noopener noreferrer"
							data-updatedate=""
							onClick={() => {
								comFunc.executeGtagEventTracking("main_link", "健康診断");
							}}
						>
							<span>健康診断予約はこちら</span>
						</a>
					</div>
				</div>
			</div>
			{/* Co-next */}
			<div className="item">
				<div className=""></div>
				<img
					alt="Co-next"
					className="co-next"
					src={require(`assets/imgs/co-next_icon.jpg`)}
					data-updatedate=""
					onClick={() => {
						comFunc.executeGtagEventTracking("main_link", "Co-next");
						window.open("https://adastria-gc.coorum.jp/users/sign_in");
					}}
				/>
				<div className="item__option">
					<div className="item__option__wrapper">
						<a
							className="co-next"
							href="https://adastria-gc.coorum.jp/users/sign_in"
							target="_blank"
							rel="noopener noreferrer"
							data-updatedate=""
							onClick={() => {
								comFunc.executeGtagEventTracking("main_link", "Co-next");
							}}
						>
							<span>Co-next(コネクト)</span>
						</a>
					</div>
				</div>
			</div>
			{/* GO意見箱 */}
			<div className="item">
				<div className={isGoikenbakoNew ? "is-new" : ""}></div>
				<img
					alt="GO意見箱"
					className="goikenbako"
					src={require(`assets/imgs/goikenbako_icon.jpg`)}
					data-updatedate=""
					onClick={(e) => {
						setCookieUpdateDate(e);
						setIsGoikenbakoNew(false);
						comFunc.executeGtagEventTracking("main_link", "GO意見箱");
						window.open("https://sites.google.com/adastria.co.jp/goikenbako/%E3%83%9B%E3%83%BC%E3%83%A0?authuser=0");
					}}
				/>
				<div className="item__option">
					<div className="item__option__wrapper">
						<a
							className="goikenbako"
							href="https://sites.google.com/adastria.co.jp/goikenbako/%E3%83%9B%E3%83%BC%E3%83%A0?authuser=0"
							target="_blank"
							rel="noopener noreferrer"
							data-updatedate=""
							onClick={(e) => {
								setCookieUpdateDate(e);
								setIsGoikenbakoNew(false);
								comFunc.executeGtagEventTracking("main_link", "GO意見箱");
							}}
						>
							<span>GO意見箱</span>
						</a>
					</div>
				</div>
			</div>
			{/* アイデアポスト */}
			<div className="item">
				<div className={isIdeaPostNew ? "is-new" : ""}></div>
				<img
					alt="Idea Post"
					className="idea-post"
					src={require(`assets/imgs/ideapost-icon.png`)}
					data-updatedate=""
					onClick={(e) => {
						setCookieUpdateDate(e);
						setIsIdeaPostNew(false);
						comFunc.executeGtagEventTracking("main_link", "Idea Post");
						window.open(`${ideaPostLink}`);
					}}
				/>
				<div className="item__option">
					<div className="item__option__wrapper">
						<a
							className="idea-post"
							href={ideaPostLink}
							target="_blank"
							rel="noopener noreferrer"
							data-updatedate=""
							onClick={(e) => {
								setCookieUpdateDate(e);
								setIsIdeaPostNew(false);
								comFunc.executeGtagEventTracking("main_link", "Idea Post");
							}}
						>
							<span>アイデアポスト</span>
						</a>
					</div>
				</div>
			</div>
		</div>
	)
};

export default RightIcons;