import * as firebase from 'firebase/app';
import 'firebase/auth';
import React from 'react';
import { createBrowserHistory } from 'history';
import './App.scss';
import LayOut from 'components/templates/layout';
import Home from 'components/pages/index';
import Store from 'components/pages/store/store';
import Media from 'components/pages/media/media';
import NotFound from 'components/pages/404';
import { Switch, Route, Router } from 'react-router-dom';
import Login from 'components/pages/Login/Login';
import Logout from 'components/pages/Logout/Logout';
import Error from 'components/pages/Error/Error';
import * as comFunc from 'common/functions';

const config = {
    apiKey: process.env.REACT_APP_FB_APIKEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID
};

firebase.initializeApp(config);

export const history = createBrowserHistory();

// WAM取得先（jsonpでcallbackで指定された関数を呼び出す）
//const WAM_SOURCE_URL = process.env.REACT_APP_WAM_DOMAIN + "/pxpadmin/bin/user_welcome.htm?callback=wamresponse";
const WAM_SOURCE_URL =
    process.env.REACT_APP_WAM_DOMAIN + '/pxpadmin/bin/user_welcome.htm?floatingBox=default&callback=wamresponse';

// WAM再認証
const WAM_RECERTIFICATION_URL = process.env.REACT_APP_WAM_RECERTIFICATION;


const App: React.FC = () => {
    // state
    const [auth, setAuth] = React.useState(false);
    const [user, setUser] = React.useState({ displayName: '', email: '', photoURL: '' });

    React.useEffect(() => {
        let cleanedUp = false;

        // 認証チェック
        const checkAuth = async (user: any) => {
            try {
                // ドメインチェック
                if (user && (await comFunc.checkAllowedDomain(user.email))) {
                    if (!cleanedUp) {
                        setUser(user);

                        // WAMデータ取得コールバック関数の追加
                        addWamCallbackMethod();
                        // WAM取得Scriptを実行
                        let wam_url = comFunc.checkEnvValue(WAM_SOURCE_URL);
                        comFunc.loadScript(wam_url, redirect);
                    }
                } else {
                    history.push('/login');
                }

            } catch (err) {
                history.push({ pathname: '/error', state: { msg: "・AWSへ接続できません" } });
            }
        };
        // ユーザ情報取得
        firebase.auth().onAuthStateChanged(user => {
            if (!user) {
                console.log("firebase auth check NG：");
                history.push('/login');
            } else {
                // チェック処理
                checkAuth(user);
            }
        });

        const cleanup = () => {
            cleanedUp = true;
        };
        return cleanup;
    }, []);

    // WAM情報のステータスチェック
    const wamStatusCheck = (e: any) => {
        let inpStatus = e.target as HTMLInputElement;

        if (inpStatus.value === "error") {
            redirect();

        } else {
            comFunc.setCookie("wam-retry", "0", "");
            setAuth(true);
        }
    }

    // リダイレクト処理
    const redirect = () => {
        // WAM再認証回数をCookieで管理(5sだけ保存)
        let retry: number = 0;
        if (comFunc.getCookie("wam-retry")) {
            retry = Number(comFunc.getCookie("wam-retry"));
        }
        // 再認証URLへリダイレクト（１回まで実行）
        if (retry < 1) {
            comFunc.setCookie("wam-retry", String(retry + 1), "5");

            console.log("WAMの取得情報失敗。redirect処理へ移行 => " + comFunc.checkEnvValue(WAM_RECERTIFICATION_URL));
            window.location.href = comFunc.checkEnvValue(WAM_RECERTIFICATION_URL);
        } else {
            setAuth(true);
            console.log("WAMの取得情報失敗。retry完遂。業務メニューなしで遷移");
        }
    }

    return (
        <div>
            <Router history={history}>
                <Switch>
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/logout" component={Logout} />
                    <Route exact path="/error" component={Error} />
                    {auth ?
                        <LayOut
                            user={{
                                url: user.photoURL,
                                name: user ? user.displayName : '',
                                latinName: user.email
                            }}
                        >
                            <Switch>
                                <Route exact path="/" component={Home} />
                                <Route exact path="/store" component={Store} />
                                <Route exact path="/media" component={Media} />
                                <Route component={NotFound} />
                            </Switch>
                        </LayOut>
                        : ''}
                </Switch>
            </Router>
            <input type="hidden" id="wam_status" onClick={wamStatusCheck} />
        </div>
    );
};

export default App;

// WAMデータ取得コールバック関数を追加
const addWamCallbackMethod = () => {
    var head = document.getElementsByTagName('head')[0];
    var script = document.createElement('script');

    // WAM情報の取得コールバック関数
    // statusをwam_statusに代入しクリックイベントで判定処理を実行
    script.text =
        "function wamresponse(json) {" +
        "  const services = json.services;" +
        "  const userdata = json.userdata;" +
        "  const element = document.getElementById('wamdata');" +
        "  const element2 = document.getElementById('wam_userdata');" +
        "  if (element.textContent === '') {" +
        "    element.textContent = JSON.stringify(services);" +
        "    element2.textContent = JSON.stringify(userdata);" +
        "    document.getElementById('wam_status').value = json.status;" +
        "    document.getElementById('wam_status').click();" +
        "  }" +
        "}";
    head.appendChild(script);
};

