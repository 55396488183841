import * as React from 'react';
import './HambugerMenu.scss';
import { mapModifiers } from 'components/_utils';
import HeadingList from 'components/molecules/HeadingList/HeadingList';
import FunctionButton from 'components/atoms/FunctionButton/FunctionButton';
import { history } from '../../../App';

interface Props {
  open?: any;
  openFnc?: any;
}

export const HambugerMenu = (props: Props) => (
  <div
    className={mapModifiers('o-hambuger-menu', props.open ? ['show'] : [''])}
    onClick={() => props.openFnc(!props.open)}
  >
    <div className="o-hambuger-menu__symbol">
      <span></span>
      <span></span>
      <span></span>
    </div>
    <>
      <div className="o-hambuger-menu__menu">
        <div className="o-hambuger-menu__menu__item">
          <FunctionButton modifiers={['mail']} href="https://mail.google.com/" />
	        <HeadingList modifiers={['line']}>
				<a href="https://mail.google.com/" target="_blank" rel="noopener noreferrer">メール</a>
			</HeadingList>
		</div>

        <div className="o-hambuger-menu__menu__item">
          <FunctionButton modifiers={['calendar']} href="https://calendar.google.com/" />
          <HeadingList modifiers={['line']}>
			<a href="https://calendar.google.com/" target="_blank" rel="noopener noreferrer">カレンダー</a>
		  </HeadingList>
        </div>

		{/* ADASTRIA TV */}
        <div className="o-hambuger-menu__menu__item">
		  <FunctionButton modifiers={['video']} href="https://adastria-gc.coorum.jp/menus/gjkpszhrwwytwah2/announcements" />
		  <HeadingList modifiers={['line']}>
		  	<a href="https://adastria-gc.coorum.jp/menus/gjkpszhrwwytwah2/announcements" target="_blank" rel="noopener noreferrer">ADASTRIA TV</a>
          </HeadingList>
		</div>

        <div className="o-hambuger-menu__menu__item">
          <FunctionButton modifiers={['hakusyaku']} href="https://app.hitto.chat/zxuQFR5nU2/XJO2rYpaat/chat" />
          <HeadingList modifiers={['line']}>
			<a href="https://app.hitto.chat/zxuQFR5nU2/XJO2rYpaat/chat" target="_blank" rel="noopener noreferrer">チャットボット</a>
		  </HeadingList>
        </div>

        <div className="o-hambuger-menu__menu__item">
          <FunctionButton modifiers={['agpt']} href="https://www.adst01.smart-generative-chat.com" />
          <HeadingList modifiers={['line']}>
			  <a href="https://www.adst01.smart-generative-chat.com" target="_blank" rel="noopener noreferrer">AGPT</a>
		  </HeadingList>
        </div>

        <div className="o-hambuger-menu__menu__logout">
          <HeadingList type={'logout'} modifiers={['logout']} onClick={() => { history.push('/logout') }}>
            ログアウト
          </HeadingList>
        </div>
        <div className="o-hambuger-menu__menu__backlayer" onClick={() => props.openFnc(!props.open)}></div>
      </div>
    </>
  </div>
);

export default HambugerMenu;